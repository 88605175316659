import React, { Fragment } from "react";
import Floatingcall from "../components/floatingbutton/Floatingcall";

function Terms(){
    return(
        <Fragment>
            <Floatingcall />
          
            <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Terms & Conditions</h2>
        </div>

        <div class="row gy-4">
          <p>Use of the website or its services is not permitted if you disagree with any of the terms of this agreement. These terms, as well as any updates or revisions to them, apply to the use of and access to the website.</p>
          <p>("Do not use the website or its services if you do not accept the terms of use, including the privacy statement form provided by the company. In using/accessing the website Helperji.in, you indicate acceptance of the terms and conditions, including any updates or revisions.</p>
          <p>It is not the company's responsibility to notify you once the posting has been updated or before such an update. Your use of the website following any modification constitutes your acceptance of the revised terms and conditions.</p>
          <p>Terms and conditions applicable to specific areas and content, as well as disclaimers and privacy policies, are also considered terms and conditions. It is important to review the terms and conditions of use periodically since they are binding on you. This website refers to all individuals and entities that access it for any reason as "users" or "you."</p>
          <p>Please read the following terms and conditions carefully before using Helperji.in, an online job posting service and resume search engine ("Helperji.in" or "Web Site").</p>
          <p>Under the terms and conditions of helperji.in, help can be provided.</p>
          <p>Based on the terms and conditions of their agreement, Helperji.in and the Client commence a working relationship. By signing these terms and conditions with helperji.in, the Client is understood to have agreed to these terms and conditions:</p>
          <p>This website is owned by the Company and is protected by its intellectual property rights. Ownership and operation of the website are the Companies. The company is solely responsible for any changes to the Terms and Conditions.</p>
          <p>Cleaning Professionals are examples of service providers. There is no employment or relationship between this company and these service providers. We connect employers with independent workers through our platform.</p>
          <h3>1. Amount paid to the company:</h3>
          <ul>
            <li>Interviews will be conducted in person and by telephone, once the requirements and salary bracket of at least 1-3 shortlisted applicants have been matched. It is the client's responsibility to pay the cleaning professional directly if after a three-day trial period she is deemed unsuitable.</li>
            <li>The Help is available to everyone regardless of their appearance, age, or faith. It's essential to judge the heart and work of a cleaning professional before choosing a reliable and trustworthy employee.</li>
            <li>Candidates placed after their trial period are subject to a one-month salary Service Charge.</li>
            <li>Alternatively, we can charge 50% of the candidate's monthly salary in the event of no replacement and 75% in the event of one replacement.</li>
            <li>Upon payment, a confirmation invoice will be sent to the client, and the placement fee/Service Charge is due immediately upon the completion of the trial period.</li>
            <li>The profiles of candidates shared via helperji.in cannot be employed independently by the client. A Client who employs a Candidate without paying the Service Charge may be prosecuted by our Legal team.</li>
          </ul>
          <h3>2. Amount to be paid to the candidate:</h3>
          <ul>
            <li>The client should be responsible for directly paying the candidate's salary.</li>
            <li>It is your responsibility to pay the salaries of house helpers who leave the job for any reason.</li>
            <li>Cleaner salary can be advanced by the Client at his/her discretion. It is not Helperji's responsibility to reclaim/refund excess salaries owed to a cleaning professional after he or she leaves the house.</li>
            
          </ul>
          <h3>3. Actions to replace:</h3>
          <ul>
            <li>In the event the below conditions are met, Helperji.in is responsible for relocating a suitable Candidate within 72 hours of the initial agreement. Workers can, however, only be hired up to a certain number. It is allowed to take up to 15 days to complete the process.</li>
            <li>Within one year of placement, we will replace a worker without charge if he or she leaves without good reason. A third replacement is offered to the client as a result of goodwill between the company and the client.</li>
            <li>It was agreed that the replacement would work the same hours, days, and times as the original employee in the first placement.</li>
            <li>Helperji.in cannot be held responsible for any changes to the replacement worker's specifications without its knowledge. In the case of a change in the job description, a new agreement will need to be signed with new specifications. Charges may apply (there may be charges).</li>
            <li>If the cleaner promises to return once he/she is well/fit for work if she/he is absent due to illness or a family emergency, a replacement will not be provided. Unless the cleaning professional returns within 7 days, we do not provide temporary replacements.</li>
            <li>If a 24 hours Help replacement isn't available within 15 days (whose salary will be adjusted accordingly in this case), a 12-hour Help service will be provided instead (whose salary will be adjusted accordingly in this case).</li>
          </ul>

          <h3>4. A police verification is required:</h3>
          <p>Clients are strongly advised to check with the police about the cleaning professional. In this way, the client can verify that the cleaning professional has not been convicted of any criminal offenses in the past. No crime committed by a candidate (civil or criminal) is the responsibility of Helperji.in.</p>
          <h3>5. The level of satisfaction with the project is:</h3>
          <ul>
            <li>Our clients are required to provide us with a 'job description' that describes the type of work and salary the cleaning professional will be doing. It is unlawful for clients to force cleaning professionals to perform tasks outside their job description or reduce their salaries. In the event that a cleaning professional leaves because something outside of her job description is requested of her, Helperji.in will neither replace the cleaning professional nor refund the placement fees paid by the client.</li>
            <li>Please give us 15 days' notice before releasing the Cleaner so that we can find you a better one. To replace the first cleaning professional, we cannot offer her a lower salary. The client cannot also change the job description of the cleaner or the tasks she is required to perform. It is not possible to send a replacement cleaning professional until the salary dues of the previous cleaning professional have been cleared. A replacement cleaning professional cannot be dispatched until the salary of the previous cleaning professional has been cleared.</li>
          </ul>
          <h3>6. Other:</h3>
          <ul>
            <li>Depending on your needs, you can choose between a one-year contract (for full-time assistance) or a six-month contract (for part-time assistance or less than eight hours), and you can renew it by paying a placement fee if you wish to purchase a replacement service from helperji.in for another year or six months.</li>
            <li>Renewal fees are incurred when a contract expires without a replacement benefit being provided.</li>
            <li>The hiring platform Helperji.in is available to users. Because of this, helperji.in does not resolve issues between employers and employees or service providers (cleaning professionals) during the employment period or after it has ended. Any disciplinary or conduct issues relating to employees hired through helperji.in should be resolved between you or, if necessary, brought to court.</li>
            <li>Employees/workers who leave due to unfair labor practices/unfair treatment are not covered by the warranty.</li>
          </ul>
          <h3>The solicitation is subject to only the jurisdiction of the Court.</h3>
          <p>When you hire a cleaning professional from helperji.in, you agree to the above-mentioned terms, even though this document has not been signed.</p>
          <p>You agree to our terms and conditions when creating an account on helperji.in or visiting our website, or hiring a cleaning professional or any candidate introduced by us.</p>
          <p>You can download the full terms of use policy for helperji.in in this Word document. Please read our terms and conditions/terms of use carefully before visiting helperji.in or creating an account.</p>
        
          <h3>Some Points to remember:</h3>
          <p>When a customer books a service with Helper Ji, he or she agrees to a monthly contract (3 months, 6 months, or 12 months, depending on the plan), with the validity starting on the date the service starts.</p>
           <p>Some of Helper Ji's update, maintenance, and provide services are:</p>
           <ul>
            <li>(For full-time or live-in employees only) Report of the COVID test conducted on the worker</li>
            <li>Verification of the police report/status</li>
            <li>The Adhar number was verified in a report</li>
            <li>Background check</li>
            <li>We provide full-time/live-in workers with cab tracking links and information about Helper Ji' bikers.</li>
            <li>Covid's hygiene kit (which includes a sanitizer and mask)</li>
            <li>Uniform for a cleaner</li>
            <li>Achieving the required job skills and soft skills for workers</li>
           </ul>
           <p>During the term of this agreement, Helper Ji will provide a replacement guarantee. The replacement period is not to exceed 10 days from the date the last Helper Ji cleaners is released. Helper Ji will issue customers credit notes if a replacement is not provided. There is a refund policy at the bottom of this page that explains how credit notes can be converted into refunds.</p>
           <p>During the screening process, Helper Ji checks each worker's police record, Aadhar number, and testimonials from previous employers (if they are provided). Our training center also provides competence training to all Helper Ji workers.</p>
           <p>Helper Ji will provide trained and professional workers in accordance with industry standards. Unwanted incidents such as theft, illegal activities, or damages are not Helper Ji's responsibility. If the situation warrants, Helper Ji will assist in filing a police complaint on behalf of the cleaner in addition to blacklisting the worker from Helper Ji's database and partner databases.</p>
           <p>By accepting these terms of use, the customer agrees not to exert mental or physical pressure on the cleaner for whom they have booked the worker in the online/written communication. The customer will pay directly to the worker through Helper Ji's online payment methods, at a cost that will be added to their existing pay, if the worker accepts additional work.</p>
           <p>Introductions should be kept confidential. If Helper Ji introduces a candidate to a customer who then introduces the candidate to a third party for full-time or part-time employment, the customer is responsible for paying Helper Ji the applicable fees. There will be a cancellation of the original agreement with the customer.</p>
           <p>Workers are required to pay the monthly charge in full for every day worked. It is not possible to reduce the monthly charge for any reason at any time. Workers will count as working even if they worked an hour on a specific day, even if their employment ends that day.</p>
           <p>In order to ensure the safety of the workers, RT PCR test results and/or Covid vaccination certificates, are required.</p>
           <p>Customers are required to provide Helper Ji with proof of their ID and address in order to ensure the safety of both workers and the company.</p>
           <p>Helper Ji is not responsible for arranging additional tests or transportation for customers who contract general illnesses, viral infections, flu, etc. while working..</p>
           
           <h3>Refund and Replacement Policy</h3>
           <p>Cleaning professional services provided by Helper Ji are subject to the following terms and conditions.</p>
           <p>Helper Ji will provide the client with a replacement worker if the worker provided by Helper Ji does not meet the following requirements:</p>
           <ul>
            <li>The employee lacks the necessary skills</li>
            <li>Lacks the motivation to work</li>
           </ul>
           <p>The performance of a worker may be affected by behavioral or medical issues, causing concern for the client or their family.</p>
           <p>In the event that a replacement is needed, additional charges will apply. Any transportation costs (for full-time/part-time workers) outside and within the state of both jobs will be the responsibility of the customer.</p>
           <p>In the event that Helper Ji does not find a replacement for the last worker it provided within 10 days of the worker's release, a refund will be issued, subject to a pro-rata deduction of subscription amounts and expenses at actual, via credit note or bank transfer to the original payment source, in accordance with the same scope of work and hours previously agreed upon.</p>
           <p>We will not provide replacements or refunds if the worker is abused, mistreated, or forced to work over 12 hours per day against their will during the period of their employment.</p>
           <p>As far as Helper Ji is concerned, the worker is not at fault if the customer decides not to hire him as a replacement. The customer is responsible for canceling the order.</p>
           <p>The worker wishes to leave the premises without being bound by the customer's will, according to Helper Ji.</p>
           <p>Without the worker's knowledge or approval, the scope of the work has changed.</p>
           <p>It is not possible to alter the job description in the case of a replacement. In such instances, Helper Ji determines the most appropriate worker based on the new booking. The refund policy does not apply to old bookings.</p>
           <p>If a replacement is made, the monthly charge cannot be lowered from the previous month's charge.</p>
           <p>An agreement may be terminated by Helper Ji without compensation. If either side cancels, the convenience fee will be deducted from the training, quality, transportation, safety, and verification costs.</p>
          </div>
     </div>
    </section>
        </Fragment>
    )
}

export default Terms;
import React from "react";
import Floatingcall from "../components/floatingbutton/Floatingcall";
import { Helmet } from "react-helmet";
function AboutUs(){
    return(

    

        <div>
            <Helmet>
      <title>About us | HelperJi</title>
     <meta name="description" content="Are you looking for professional cleaning services in Noida? Don't waste time, book our service now with HelperJi Just call @ +91-7800-7800-95. 24x7 Hours Availability." />
      <meta name="keywords" content="Cleaning Services, Best Cleaning Services in Noida, Book Home Cleaning services Online in Noida, House Cleaning Services in Noida, Office Cleaning Services in Noida, Washroom Cleaning Services in Noida, Clean and Sanitization Services in Noida" />
      </Helmet>
          
    
            <Floatingcall />
            <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>About Us</h2>
          <p>A Cleaning company that helps with deep cleaning! Helper Ji</p>
        </div>

        <div class="row gy-4">
          <div class="col-lg-6">
            <img src="/assets/img/aboutbanner1.jpg" class="img-fluid rounded-4 mb-4" alt="" />
            <p>Home is a reflection of who you are, and there is no place better than it. A freshly cleaned house has an indescribable feeling when you walk into it. Due to a busy schedule, repetitive tasks, stress at work, and responsibilities, it can be difficult to accomplish household chores and deep cleaning. When you hire the right cleaning company, you can get deep cleaning for a reasonable price.</p>
            <p>Count on our help for professional deep cleaning. Helper Ji is one of the best home cleaning companies in Noida. In addition to our extensive range of cleaning services, we offer the most accurate and effective results. A highly trained and skilled team of professional cleaners provides clients with expert cleaning services. Every job our cleaners perform is done with a combination of cleaning equipment and expertise.</p>
          </div>
          <div class="col-lg-6">
            <div class="content ps-0 ps-lg-5">
              <h4 class="fst-italic">
              We are your one-stop shop for all household and commercial cleaning services. To stay in line with its mission, HelperJi promotes healthy living.
              </h4>
              <p>
              At Helper Ji Cleaning Agency, we provide exceptional house cleaning, office cleaning, bathroom cleaning, and sanitizing services to exceed domestic and commercial requirements. The cleaners in our team are highly skilled and we service the whole of the local area. Providing a top-quality deep cleaning service on an adjustable schedule is the goal of our polite, friendly, and efficient staff. Call us today to schedule an appointment. We offer a wide range of cleaning services.
               </p>

              <div class="position-relative mt-4">
                <img src="/assets/img/about.jpg" class="img-fluid rounded-4" alt="" />
               
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section id="services" class="services sections-bg">
          <div class="container">
          <div class="row gy-4 align-items-center">

            <div class="col-lg-6">
              <img src="/assets/img/mission.png" alt="" class="img-fluid" />
            </div>
  
            <div class="col-lg-6">
  
              <div class="stats-item d-flex align-items-center">
                <h2>The mission of our organization</h2>
              </div>
  
              <div class="stats-item d-flex align-items-center">
                <p>To achieve the highest standards of cleaning, our company provides a variety of cleaning services. It is not just about cleaning that Helper Ji stands for. Working together with clients and establishing relationships is at the heart of it.</p>
              </div>

              <div class="stats-item d-flex align-items-center">
              <p>We strive to provide the highest quality cleaning to our clients to meet their expectations. The goal of our organization is to make working here an enjoyable and productive experience. </p>
              </div>
  
            </div>
          </div>
        </div>
        </section>

        <section id="stats-counter" class="stats-counter">
        <div class="container" data-aos="fade-up">
        <div class="row gy-4 align-items-center">
          <div class="col-lg-6">
            <img src="/assets/img/vision.jpg" alt="" class="img-fluid" />
          </div>
          <div class="col-lg-6">
            <div class="d-flex align-items-center">
              <h2>We are committed to our vision</h2>
            </div>
            <div class="d-flex align-items-center">
              <p>It is everyone's right to live and work in an environment that is safe, healthy, and enjoyable. Providing world-class cleaning services is our goal. People deserve to be taken care of.</p>
            </div>

            <div class="d-flex align-items-center">
            <p>Maintaining a clean, quiet working environment and establishing long-term client relationships requires us to take time to understand our client's needs and provide them with the most appropriate level of professional conduct. </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="pricing" class="pricing sections-bg">
      <div class="container" data-aos="fade-up">
        <div>
          <h2 style={{paddingBottom:'30px'}} >We are one of the leading companies in the industry when it comes to cleaning services.</h2>
          <p>Founded with the intent to establish itself as a leading eco-friendly cleaning company, Helper Ji provides professional deep cleaning services. In recent years, we have established ourselves as one of the most trustworthy deep cleaning companies.</p>

            <h2>Cleaning Services You Can Rely On</h2>
            
            <p>Throughout the history of our company, we have provided professional deep cleaning services for both domestic and commercial clients. Over the years, we have strived to achieve continuous client satisfaction through consistency. Our professional cleaning services rank among the best in the industry.
            </p>
            <h2>The professionalism of the staff</h2>
            <p>As a result of our hardworking, sincere, and dedicated team members, we're able to handle carpet cleaning, deep cleaning of office spaces, and house cleaning orders. We train, recognize, and certify our employees. Because of their skills and expertise, we have achieved success in the market.
</p>
<p>We are the right place for you if you are looking for a reliable Cleaning staff to reduce your never-ending house chores and save a lot of time. It is important to choose your cleaners carefully. As the wrong choice can be a total headache for your home and family.
</p>
<p>Hiring a Covid-19-safe worker is easy and convenient. Engage workers directly from multiple nearby locations through us. Since our workers are highly skilled, we provide our clients with the highest quality service as a mark of our respect for their trust.
</p>
<p>By helping workers find jobs in their area, Helperji's platform has been created to help. People can locate workers locally through the Helper Ji, which eliminates the need for a middleman. The best cleaning persons are available at HelperJi, making your life much easier. Bringing people together is what we do. 
</p>
<p>Having a consistent service approach and a hard-working team has enabled us to establish ourselves as a leading provider of cleaning professionals. Without the trust we received from our clients, we would not have been able to achieve our achievements. As one of our top priorities, providing excellent cleaning staff to our customers is one of our top priorities.
</p>
<p>Throughout our growth, we have committed ourselves to excellence to meet the needs of our clients.
</p>
        </div>
        </div>

      
    </section>
        </div>
    )
}

export default AboutUs;
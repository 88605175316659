import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function HeroNew(){
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
    return(
        <Fragment>
         
  <section id="hero" className="hero">
    <div className="web-version">
    <div className="container-fluid position-relative">
      <div className="row gy-5" data-aos="fade-in">
      <div className="col-lg-12 order-1 order-lg-2">
          <img src="assets/img/banner.jpg" className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="100" />
        </div>
      </div>
      </div>
      </div>

      <div className="mob-version">
    <div className="container-fluid position-relative">
      <div className="row gy-5" data-aos="fade-in">
      <div className="col-lg-12 order-1 order-lg-2">
          <img src="assets/img/banner-mob.jpg" className="img-fluid mx-auto" alt="" data-aos="zoom-out" data-aos-delay="100" />
        </div>
      </div>
      </div>
    </div>
    
  </section>
        </Fragment>
    )
}

export default HeroNew;
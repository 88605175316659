import React, { Fragment, useRef } from "react";
import emailjs from "emailjs-com";

function Bookform(){
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wq9wv4j",
        "template_vsyfdsw",
        form.current,
        "21KNMZZYc-26Rqtmt"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("One of our executives will connect with -you shortly to help you complete this booking, please call  7800-7800-95 in case of urgent requirements");
        },
        (error) => {
          console.log(error.text);
          alert("FAILED...", error);
        }
      );
  };

    return(

        <Fragment>
            <section id="about" className="about d-flex justify-content-center align-items-center ">
      <div className="container align-items-center justify-content-center booking shadow" data-aos="fade-up">

        <div className="section-header">
          <h3>Book Now</h3>
          <p style={{color:'#fff'}}>Please fill the below form for your query or Connect with one of our experts and book your helper with 60 Seconds</p>
          <h2 style={{color:'#fff', paddingTop:"10px"}}>7800-7800-95</h2>
        </div>

        <div class="row gy-4 d-flex align-items-center justify-content-center">

        <form ref={form} onSubmit={sendEmail} method="post" class="php-email-form">
          
               
          <p class="text-center"> <input type="text" size="50" name="user_name" style={{height:'6vh'}} placeholder="Your Name" required /></p>
   
              <br />
               
          <p class="text-center"><input type="text" size="50" name="Telephone" style={{height:'6vh'}} pattern="^\d{10}$" placeholder="Your Mobile Number" required /></p>
   
              <br />
               
          <p class="text-center"><input type="email" size="50" style={{height:'6vh'}} name="user_email" pattern="[^ @]*@[^ @]*" placeholder="E-mail Address" required /></p>
   
              <br />
               
          <p class="text-center"> <input type="text" size="50" name="Address" placeholder=" Enter Your Address" style={{height:'6vh'}} required /> </p>
   
              <br />
   
               
          <p className="text-center">
              <select type="text" name="Services" style={{width:'415px', height:'6vh'}} required>
                <option>Choose Your Service</option>
                <option value="1">Home Cleaning</option>
               <option value="2">Office Cleaning</option>
               <option value="3">Washroom Cleaning</option>
               <option value="5">Sanitization Service</option>
               <option value="6">Car Wash Service</option>
               <option value="7">Private Security Service</option>
                </select>
          </p>
              <br />
               
        <p className="text-center">
          <textarea cols="54" name="message" placeholder="Comment here"> Comment here</textarea>
        </p>
        <p className="text-center">
          <label>
            <input type="checkbox" required />
            I accept the terms and conditions.
          </label>
        </p>
        <div className="text-center"><button type="submit" className="booknow shadow">Send Message</button></div>
   
      </form>  
    </div>
    </div>
    </section>
        </Fragment>
    )
}

export default Bookform;
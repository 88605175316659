import React, { Fragment } from "react";
import Bloghome from "../components/Bloghome";
import Floatingcall from "../components/floatingbutton/Floatingcall";
import Hero from "../components/Hero";
import Homeservices from "../components/Homeservices";
import Mainnew from "../components/Mainnew";
import Testinomials from "../components/Testinomials";
import WhyChooseUs from "../components/WhyChooseUs";
import { Helmet } from "react-helmet";
import HeroNew from "../components/HeroNew";

function Home() {
    return(
        <Fragment>
         <Helmet>
         <title> Professional Cleaning Services in Noida | Deep Cleaning Services in Noida </title>
         <meta name="title" content="Professional Cleaning Services  in Noida | Deep Cleaning Services in Noida" />
        <meta name="description" content="We offer affordable deep cleaning services in Noida, covering bathroom cleaning, toilet cleaning, and washroom cleaning services. Get in touch 24/7 with +91-7800-7800-95." />
         <meta name="keywords" content="Cleaning Services, Best Cleaning Services in Noida, Book Home Cleaning services Online in Noida, Washroom Cleaning Services in Noida, Office Cleaning Services in Noida, Bathroom Cleaning Services in Noida, Cleaning and Sanitization Services in Noida" />
         </Helmet>
        <Floatingcall />
        <HeroNew />
        <Mainnew />
        <Homeservices />
        <WhyChooseUs />
        <Testinomials />
        <Bloghome />
    </Fragment>
)
}

export default Home;


import React, { Fragment } from "react";
import '../components/mainnew.css';

function Main(){
    return(
        <Fragment>
            <section className="about-us-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-image">
                                <div className="about-shape1"></div>
                                <div className="about-shape11"></div>
                                <img src="./assets/img/newhero.png" className="about-img1 img-fluid" alt="" />
                                    <img src="./assets/img/aboutus2.png" className="about-img2 img-fluid" alt="" />
                                        <div className="about-shape2"></div><div className="about-shape22"></div></div></div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="about-content">
                                                <div className="about-subtitle">
                                                    <h3>HelperJi is one of the best Cleaning Service providers in the NCR.</h3></div>
                                                    <div className="about-desc"><p>Helperji offers <strong><a href="https://www.helperji.in/">Professional Cleaning Services in Noida</a></strong>. With a great deal of experience in the cleaning industry, our employees are experts in their fields. Our services are guaranteed to meet your expectations.</p></div>
                                                    <div className="about-iconbox">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="box-item d-flex">
                                                                    <div className="box-icon">
                                                                        <i className="bi bi-check-circle"></i></div>
                                                                        <div className="box-title"><h6><a href="https://www.helperji.in/home-cleaning-services">Commercial Cleaning</a></h6><p>HelperJi provides the <b><a href="https://www.helperji.in/commercial-cleaning-services">best commercial cleaning services</a></b> for you. Taking pride in providing office cleaning services in Noida.</p></div></div>
                                                                        <div className="box-item d-flex"><div className="box-icon"><i className="bi bi-check-circle"></i></div>
                                                                        <div className="box-title"><h6><a href="https://www.helperji.in/housekeeping-manpower-services">Housekeeping Manpower</a></h6><p> Contact us today for professional <b><a href="https://www.helperji.in/housekeeping-manpower-services">Housekeeping Manpower services</a></b>. Book now at affordable rates with the best service quality.</p></div></div></div>
                                                                        <div className="col-md-6"><div className="box-item d-flex"><div className="box-icon"><i className="bi bi-check-circle"></i></div>
                                                                        <div className="box-title"><h6><a href="https://www.helperji.in/security-guard-services">Security Guard Services</a></h6><p>HelperJi provides the <b><a href="https://www.helperji.in/security-guard-services">best security guard services</a></b> for you. Taking pride in providing best security services in Noida.</p></div></div>
                                                                        <div className="box-item d-flex"><div className="box-icon"><i className="bi bi-check-circle"></i></div>
                                                                        <div className="box-title"><h6><a href="https://www.helperji.in/private-security-services">Private Security Service</a></h6><p>take advantage of our Helper Ji <b><a href="https://www.helperji.in/private-security-services">personal security services</a></b>, and become completely tension-free. </p></div></div></div></div></div>
                                                                        <div className="about-morebtn d-flex"><div><a href="tel:7800 7800 95">Call Us Anytime</a></div>
                                                                        <div className="d-flex"><div className="phone-icon"><i className="bi bi-telephone-forward-fill"></i></div>
                                                                        <div className="phone-number"><h1> 7800 7800 95</h1></div>
                                                                        </div></div></div></div></div></div>
                                                                        
                                                                        </section>

        </Fragment>
    )
}

export default Main;
import React, { Fragment } from "react";
import Contactus from "../components/Contactus";
import { Link } from "react-router-dom";
import Floatingcall from "../components/floatingbutton/Floatingcall";
import { Helmet } from "react-helmet";

function CommercialCleaningServices(){

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

    return(
        <Fragment>
          <Helmet>
            <title>Commercial Cleaning Services | Office Cleaning Services in Noida</title>
            <meta name="title" content="Commercial Cleaning Services | Office Cleaning Services in Noida" />
            <meta name="description" content=" Experience the difference with our professional commercial cleaning services in Noida. We specialize in creating immaculate workspaces. Contact us today for top-tier 'office cleaning services in Noida' and ensure a clean and welcoming environment for your business." />
            <meta name="keywords" content="Cleaning Services, Best Cleaning Services in Noida, Office Cleaning Services in Noida, commercial cleaning services, office cleaning services, commercial cleaning services near me, office cleaning services near me, office cleaning, office cleaning services, Commercial Cleaning Services in noida." />
          </Helmet>
            <Floatingcall />
            <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Commercial Cleaning Services</h2>
          <p>Are you looking for <b>best Commercial Cleaning service near me</b> then contact Helper Ji today. </p>
        </div>

        <div class="row gy-4">
          <div class="col-lg-6">
            <img src="/assets/img/officeclean.jpg" class="img-fluid rounded-4 mb-4" alt="" />
            <div class="text-center"><Link to='/contact-us' className="booknow" onClick={scrollToTop}>Book Now</Link></div>
          </div>
          
          <div class="col-lg-6">
            <div class="content ps-0 ps-lg-5">
              <p>
              HelperJi provides the best commercial cleaning services for you. Taking pride in providing <a href="https://www.helperji.in/commercial-cleaning-services"><strong> office cleaning services in Noida </strong></a>, Helper Ji is one of the best office cleaning companies. Our cleaning concept uses innovative deep cleaning methods to represent a professional approach to office cleaning. 
              </p>
              <ul>
                <li><i class="bi bi-check-circle-fill"></i>We clean the ceiling fans, air conditioner vents, and lights, as well as vacuum the coves. </li>
                <li><i class="bi bi-check-circle-fill"></i>The computers and pin boards are cleaned, the electronics are dusted, and the pin boards are vacuumed by our team.</li>
                <li><i class="bi bi-check-circle-fill"></i>The computers and pin boards are cleaned, the electronics are dusted, and the pin boards are vacuumed by our team.</li>
                <li><i class="bi bi-check-circle-fill"></i>Pinboards need to be vacuumed, computers need to be cleaned, and dusting needs to be done while office cleaning.</li>
                <li><i class="bi bi-check-circle-fill"></i>The team cleans workstations, vacuums pinboards, and dusts electronic devices as needed.</li>
              </ul>
               </div>
          </div>
        </div>
      </div>
    </section>
     
    <section id="services" class="services sections-bg">
          <div class="container" data-aos="fade-up">
    
            <div class="row gy-4">
              
              <div class="col-lg-6">
                <div class="content ps-0 ps-lg-5">
                  <h3>We follow safety standards when deep cleaning your office</h3>
                  <p>
                    At Helper Ji Services, we offer a variety of deep cleaning services for workplaces. It is of utmost importance to us as an office cleaning company to ensure the safety and health of employees. 
                  </p>
                  <p>
                    In addition to delivering high-quality <strong><a href="https://www.helperji.in/commercial-cleaning-services">commercial cleaning services</a></strong> on-site, we are recognized as a trusted cleaning company. As a result of complying with government and industry standards, we provide services of the highest quality.
                  </p>
                  <p>Providing you with commercial cleaning services requires extensive training on the part of Helper Ji's cleaners. Our company provides cleaning services for businesses through a team of professionals who are certified in this field.</p>
                  <p>When providing corporate cleaning services, our professionals use safe and effective cleaning equipment and tools.</p>
                  
                  <div class="text-center"><Link to='/contact-us' className="booknow" onClick={scrollToTop}>Book Now</Link></div>
                </div>
              </div>
              <div class="col-lg-6">
                <img src="/assets/img/officecleaning.jpg" class="img-fluid rounded-4 mb-4" alt="" />
                
              </div>
            </div>
    
          </div>
        </section>
        <section id="services" class="services sections-bg">
        <div className="container">
          <h3>Let our high-quality cleaning services take care of your office</h3>
          <p>For us, there is no difference between a small business and a large one. <strong><a href="https://www.helperji.in/">Office cleaning services</a></strong> are available from Helper Ji at a very reasonable price. A professional office cleaning service can enhance your staff's productivity.  You can rely on Helper Ji for professional office cleaning at an affordable price.</p>
          <h3> In detail, we offer the following cleaning services:</h3>

          <ul>
                <li><i class="bi bi-check-circle-fill"></i> Clean tiles on the floor</li>
                <li><i class="bi bi-check-circle-fill"></i> Keeping your sofa clean</li>
                <li><i class="bi bi-check-circle-fill"></i> <strong><a href="https://www.helperji.in/">Deep cleaning services in Noida</a></strong> Services related to sanitation</li>
                <li><i class="bi bi-check-circle-fill"></i> Keeping the conference hall clean</li>
                <li><i class="bi bi-check-circle-fill"></i> Ensure that door handles and light switches are free of fingerprints and smudges </li>
                <li><i class="bi bi-check-circle-fill"></i> Interior and exterior cabin glass cleaning</li>
                <li><i class="bi bi-check-circle-fill"></i> Keeping the table clean</li>
                <li><i class="bi bi-check-circle-fill"></i> Cleaning the pantry</li>
                <li><i class="bi bi-check-circle-fill"></i> Cleaning of the walls</li>
                <li><i class="bi bi-check-circle-fill"></i> Taking care of your computer</li>
                <li><i class="bi bi-check-circle-fill"></i> Clean up of chairs</li>
              </ul>

        </div>
        </section>


            <Contactus />  

        </Fragment>
    )
}

export default CommercialCleaningServices;
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Floatingcall from "../components/floatingbutton/Floatingcall";


function DonateUs(){

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

    return(
        <Fragment>
            <Floatingcall />

            <section id="about" class="about">
             <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Donate Us</h2>
          <p>You are always welcome to donate at HelperJi.</p>
        </div>

        <div class="row gy-4">
           <div class="col-lg-6">
            <img src="/assets/img/donateus.jpg" class="img-fluid rounded-4 mb-4" alt="" />
            <img src="/assets/img/donate_us.jpg" class="img-fluid rounded-4 mb-4" alt="" />
            <div class="text-center"><Link to='/contact-us' className="booknow" onClick={scrollToTop}>Book Now</Link></div>
            </div>
          
          <div class="col-lg-6">
            <div class="content ps-0 ps-lg-5">
              <h3 class="fst-italic">
              Have you ever thought about donating old or used items to those in need? 
              </h3>
              <p>There is no limit to the number of items you can donate, old or new. There is often a lack of clothes available to poor and underprivileged people. Each year, a child's clothing becomes too small for him or her. You should donate your old clothes instead of discarding them, since what you would consider waste might be valuable to someone else.</p>
              
              <p>Besides being able to donate used toys, you can also donate furniture, books, shoes, computers, laptops, blankets, mattresses, bedding, utensils, groceries, household goods, stationary, textbooks, notebooks, cleaning products, notebooks, bed sheets, bags, crockery, suitcases, as well as used toys. Food items such as groceries, fruits, vegetables, rice, sugar, and cooking oil.</p>
              <p>Donating items according to your desire will allow you to reach the needy through Helper Ji. Donating anything of your choice is easy because our process is step-by-step.</p>
              <ul>
            <li><i class="bi bi-check-circle-fill"></i>You may connect with us by calling our number or visiting our website for more details.</li>
            <li><i class="bi bi-check-circle-fill"></i>An agent from our company will contact you to arrange a collection of the items. In the case of clothes, they are sorted out and mended if necessary by sewing.</li>
            <li><i class="bi bi-check-circle-fill"></i>We make sanitary pads and socks from old, tattered clothes. The other items are just screened and selected for our helpers after we screen them.</li> 
            <li><i class="bi bi-check-circle-fill"></i>We use the following method for collection. Donations of items can be picked up from homes and offices by our company.</li>
            <li><i class="bi bi-check-circle-fill"></i>As well as drop-off points in cities, we have also set up drop-off points for the used materials.  </li>
              </ul>
              </div>
          </div>
        </div>
        </div>
    </section>

    <section id="services" class="services sections-bg">
          <div class="container" data-aos="fade-up">
    
            <div class="row gy-4">
              
              <div class="col-lg-6">
                <div class="content ps-0 ps-lg-5">
                <h2>Is there anything holding you back? We at Helper Ji believe :</h2>
                  <p><strong>“It's not about the things you have or even the achievements you make. It's about lifting others and making them stronger.”</strong></p>
                  <p>Several categories of helpers all around us play different roles in helping us live healthy and happy life.</p>
                  <p>Cleaning professionals help us make our house and office cleaner. The myth that all heroes wear capes isn't true. They fight with buckets and mops instead of suits and coats.</p>
                  <p> Cleaning is necessary to protect your home and workplace from bacteria and illnesses. Cleaners work hard to make sure that your home and workplace remain free of dirt and deadly diseases. Having them in our lives would make our lives complete.</p>
                  <p> Whether it's food, clothes, money, or anything else you feel like donating, you'll help a worker afford their next meal and ensure their survival.  Cleaning teams will share the money donated equally so that they can spend it however they wish.</p>
                  <p>It is greatly appreciated that you will support us in any way you can. We depend on them every day to help us keep our homes and society clean. It is much easier for us to get along with different types of people. As a result of these responsibilities, we extend our relationships with our friends, family members, and society as a whole.</p>
                  <p>All those individuals out there deserve our respect, so let us show them that we appreciate them in every way. Let's be kind and honest with each other.</p>
                  <p>The work of each individual is equally valuable, and no one should give their work a lesser or higher priority than that of the next individual.</p>
                  <p>We all must connect to make this world a better place. Assisting us in our pursuit of a better tomorrow is only made easier by our helpers and assistants. So, we at Helper Ji never fail to support our helpers in any way we can.</p>
                  <h4>Thank you very much.</h4><br/>
                  <div class="text-center"><Link to='/contact-us' className="booknow">Book Now</Link></div>
                  
                </div>
              </div>
              <div class="col-lg-6">
              <img src="/assets/img/donate.png" class="img-fluid rounded-4 mb-4" alt="" />
                <img src="/assets/img/donateusnew.png" class="img-fluid rounded-4 mb-4" alt="" />
                
              </div>
            </div>
    
          </div>
        </section>
        </Fragment>
    )
}

export default DonateUs;
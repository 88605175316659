import React from 'react'
import Menu from './Menu'
import Topbar from './Topbar'

const Header = () => {
  return (
    <div>
      <Topbar />
      <Menu />
    </div>
  )
}

export default Header

import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Contactus from "../components/Contactus";
import Floatingcall from "../components/floatingbutton/Floatingcall";
import { Helmet } from "react-helmet";

function SecurityGuardServices(){

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

    return(
        <Fragment>
          <Helmet>
            <title>Security Services In Noida | Guard Services In Noida</title>
            <meta name="tttle" content="Security Services In Noida | Guard Services In Noida" />
            <meta name="description" content="Top-rated Security Services in Noida. Reliable and professional guard services in Noida for residential, commercial, and industrial properties." />
            <meta name="keywords" content="Event security services, Personal protection officer, Private security officer, Private security agents, Armed security services, Private Security Guard, Personal security guard, Private security personnel, Personal security officer, Private security service" />
          </Helmet>
            
            <Floatingcall />
         
            <section id="about" class="about">
            <div class="container" data-aos="fade-up">

            <div class="section-header">
            <h2>Security Guard Services</h2>
            <p>Helper Ji's Premier Security Guard Services: Feel Safe and Confident </p>
             </div>

           <div class="row gy-4">
           <div class="col-lg-6">
            <img src="/assets/img/security1.jpeg" class="img-fluid rounded-4 mb-4" alt="" />
            
            </div>
          
          <div class="col-lg-6">
            <div class="content ps-0 ps-lg-5">
              <h3 class="fst-italic">
              With Helper Ji's Security Gaurd Services, we ensure your safety
              </h3>
              <p>
              Helper Ji takes pleasure in offering the best security guard services available in Noida, making sure your property is safe and secure. As one of the top security guard firms, we take great pride in our professional and thorough approach to protecting your assets, which demonstrates our devotion to security.
              </p>
              <p>Our well-trained security officers are prepared to manage a range of security requirements, such as keeping an eye on entrances, policing the area, and handling emergencies. Whether you require security for an event, residential complex, or business office, our staff guarantees all-encompassing protection by taking a proactive approach to safety.</p>
              <p>The security guards at Helper Ji are skilled in handling conflicts, responding to emergencies, and utilising contemporary security technologies in addition to receiving basic security training. Our guards keep a close eye on CCTV cameras, secure entryways, and make sure that all safety procedures are properly followed.</p>
              <p></p>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section id="services" class="services sections-bg">
          <div class="container" data-aos="fade-up">
    
            <div class="row gy-4">
              
              <div class="col-lg-6">
                <div class="content ps-0 ps-lg-5">
                  <h2>
                  Why Choose Helperji's Private Security Services:
                  </h2>
                  <p>
                  Helper Ji is renowned for its professionalism and dedication to perfection, in addition to providing top-notch on-site security services. Because they get extensive training, our security staff members are well-equipped to properly manage and mitigate hazards.
                  </p>
                  <h3>Among our security services are:</h3>
                  <p>Monitoring of entry and exit points</p>
                  <ul>
                    <li>constant surveillance of the property</li>
                    <li>Crisis intervention and emergency response</li>
                    <li>CCTV surveillance and monitoring</li>
                    <li>Controlling access and managing visitors</li>
                    <li>Services for event security</li>
                    <li>Security guard services for homes and businesses</li>
                    <li>Personalised security measures catered to your requirements</li>
                  </ul>
                  <div class="text-center mt-3"><Link to='/contact-us' className="booknow" onClick={scrollToTop}> Book Now</Link></div>
                </div>
              </div>
              <div class="col-lg-6">
                <img src="/assets/img/security3.png" class="img-fluid rounded-4 mb-4" alt="" />
                
              </div>
            </div>
    
          </div>
        </section>

       <Contactus />
        </Fragment>
    )
}

export default SecurityGuardServices;
import React, { Fragment } from "react";
import Contactus from "../components/Contactus";
function ContactUs(){
    return(
        <Fragment>
           
            <Contactus />
        </Fragment>
    )
}

export default ContactUs;
import React, { Fragment } from "react";
import Floatingcall from "../components/floatingbutton/Floatingcall";

function Privacy(){
    return(
        <Fragment>
            <Floatingcall />

            <section id="about" class="about">
        <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Privacy Policy</h2>
        </div>

         <div class="row gy-4">
            <p>Assisting users to find, coordinating, and maintaining high-quality health care is possible through Helper Ji's many services. To improve the user experience and to provide our services, we use the information we collect through our Privacy Policy.</p>
            <p>It is the website, the web pages, the mobile applications, and the mobile websites of Helper Ji, as well as employers seeking services through Helper Ji, as well as individuals who seek to advertise their services through Helperji (" Cleaning professionals ") and customers of Helper Ji's various services, including those provided through the Site and by telephone ("Services")".</p>
            <p>We, refer to Helperji as "the Company," "we," "us," and "our." Our subsidiaries each have their privacy policies. The Helperji Site or Services may be used by third parties who are not covered by this Privacy Policy. If such entities register with Helperji, separate terms apply to the Helperji Site and Services.</p>
            <strong>This policy outlines our practices for collecting, using, and disclosing personal information.</strong> 
            <h3>1. Collecting information</h3>
            <p>Our website collects Non-Personal Information about our users in addition to Personal Information. A person's full name, email address, and any other information they provide can be used to identify or contact them as Personal Information.</p>
            <p>" A person's Non-Personal Information is information that cannot be used to identify or contact them. It is not associated with any other information that can be used to identify or contact them. Passively collected information on our Site, including usage data you generate, cannot be linked to your Personal Information.</p>
             <h3>The information you provide</h3>
            <p>You provide information to us when you register, post a requirement, create a profile, communicate with us through the Site, request care over the phone, or use our mobile applications whether you are a Site Visitor or Registered User.</p>
            <p>If the information you provide about yourself or others is provided by you with the representation and warranty that you have the authority to do so. You have the choice of how you share your Personal Information, but we appreciate any communication you may choose to send us.</p>
            
            <h3>During The registration process</h3>
            <p>The online registration forms on Helperji allow you to register directly. You don't have to worry about collecting and storing your information with us.
            <br />Helper Ji collects a variety of information about you, including your credit card type, expiration date, security code, billing address, and birthdate.
          </p>

            <h3>Whenever a requirement is posted</h3>
            <p>You can provide us with additional information when creating an automatic job posting. Additionally, the following information may need to be included:</p>
            <ul>
              <li>We would appreciate being able to provide care based on your schedule, location, rate per hour, number of children, and ages.</li>
              <li>When applying for a job, please indicate if you have any special qualifications, such as driving skills.</li>
              <li>Select your favorite pictures from your collection.</li>
              <li>Your health information can be collected if you decide to share it.</li>
              <li>The requirements posting can be supplemented with additional information.</li>
            </ul>


            <h3>During the profile posting process</h3>
            <p>As part of our system, jobseekers provide additional information about themselves, which we collect and store. As well as the information below, you may include the following:</p>
            <ul>
              <li>You should provide information about your qualifications, availability, location, level of education, languages you speak, and driving ability, as well as your availability, skill level, and level of education.</li>
              <li>The provision of services that are in line with your expertise</li>
              <li>The contact information for you can be found here</li>
              <li>Your choice of pictures</li>
              <li>Referrals are possible</li>
              <li>There may also be other information in your profile that you choose to include:</li>
            </ul>

            <h3>Using the phone</h3>
            <p>Visiting or registering on the Site or making calls to HelperJi's phone number may allow visitors to provide HelperJi with Personal Information. <br />
              <br />Sharing information about someone other than yourself requires your verification that you have permission to do so.<br />
              <br />As well as online communication, group messaging, and payment services, we also offer telephone, e-mail, and postal services. Some Registered Users may provide Personal Information through the Site.
            </p>
            <h3>Visitors registered users, and other information provided by others</h3>
            <p>As well as information provided by users and visitors to the site, Helper Ji provides his own information as well. Registered Users of Helper Ji may also post reviews about other Jobseekers/cleaning professionals, in addition to reviews about individual Jobseekers/cleaning professionals. </p>
            <h3>Verification of your information and representations by third parties</h3>
            <p>
              As part of Helper Ji's Terms of Service, third parties will periodically scan your Personal Information as part of your registration for the Site and Service.

             <br /><br />There are many types of databases, such as national criminal databases, sex offender registers, certain media streams, terrorist watch lists, criminal and fugitive watch lists, fraud watch lists, and law enforcement reports.

            <br /><br />Verification checks may require your assistance, such as providing your name, address, email address, etc., as well as making representations and warranties about your criminal background ("Verification Checks"). Verification Checks will be conducted by Helper Ji and their results will be sent to him.

            </p>
            <h3>Using a third-party background check company</h3>
            <p>
              To safeguard the safety and integrity of the Site and Helper Ji users, Helper Ji uses your Personal Information for periodic background checks on you.

              <br /> <br />Continually participating in our Services is associated with using our Services and interacting with our Site. Some or all Registered Users may be able to request a background check on themselves, Job Seekers, Cleaning professionals, or anyone else they may be considering hiring. 

            </p>

            <h3>Using other sources</h3>
            <p>
              As part of regulatory compliance, ensuring data accuracy, better understanding your interests, and preventing fraud, we can also obtain information from other sources.
            </p>
            <h3>The information we collect about you has been explicitly authorized by you</h3>
            <p>Personal Information about you may be collected from time to time by request of your permission. Your location may also be accessed and stored by us if you permit us. As long as you grant us your permission, we will have access to and store such information unless you discontinue this by adjusting your device's settings.</p>
            <h3>The following methods are used to share information</h3>
            <p>Besides using your information for the purposes described above, Health Ji may also share your information with third parties as described below. Your privacy is important to us, so please read our policy carefully about how we share Personal Information. Our sharing policy must be followed when providing Personal Information to us.</p>
            <p>Users may provide personal testimonials and endorsements on our Site and other marketing materials. There are some testimonials and endorsements that contain non-personal information, including a Registered User's first name, first initial, hometown, and state. The consent of registered users is also required for the provision of additional personal information.</p>
            <h3>In collaboration with vendors/service providers</h3>
            <p>In addition, Helper Ji discloses Personal Information with vendors that provide email, demographic, and geolocation services on its behalf, as well as a background check, verification, payment processing, classified ads, and SMS messages to registered users' mobile phones.
                Several factors must be considered. Helper Ji provides Personal Information only to its vendors who agree to maintain high privacy standards.
              </p>

              <h3>
                Search engine listings such as Google and others
              </h3>
              <p>There are several Employer job postings and Jobseeker profiles that have information that employers, job seekers, Cleaning professionals, or anyone else interested in caregivers or jobs can find, such as a photo, first name, first initial or last name, city, state, and job description.</p>
              <p>It is also possible for third-party search engines (like Google) to link to your Helper Ji posting, thereby allowing the public access to your information. Furthermore, third-party search engines and other vendors that provide classified advertising services may use your data.</p> 
              <h3>Direct mail from unknown companies will be sent to you by third parties</h3>
              <p>The information we collect about you, including your name, postal address, and other information we know about you, such as your age, the presence of children, and the type of care you require, may be shared with third parties in the business of facilitating communications, such as direct mail brokers, for them to send you postal mail advertisements from companies whose products may interest you.</p>
              <p>You will never see your email address shared with third parties for this purpose, and third parties will never have direct access to your personal information to advertise their products to you. If you prefer not to have your information shared with these third-party communication facilitators for these advertising purposes, you can change your account settings.</p>
              <h3>By the law or for safety reasons</h3>
              <p>Depending on the law or in our sole discretion, we may disclose the information we collect about you if it is required by law or if we deem it reasonable to comply with the law, law enforcement requests, orders, or legal processes (whether or not such disclosure is required by applicable law), or to protect Helper Ji or others' rights or property.</p>
              <p>Our community members and Registered Users may also be subject to disclosure of their personal information if they believe it is necessary to protect themselves (or you).</p>

              <h3>In connection with our affiliate program</h3>
              <p>We may share the information we collect about you with one or more of our affiliates or subsidiaries to provide you with the Services, operate the Site, and other commercial activities.</p>
              <h3>Your Consent is Required</h3>
              <p>We may also allow third parties to access and use your Personal Information.</p>
              <h3>Checking background information</h3>
              <p>Background checks are available through Helper Ji from a variety of consumer reporting agencies. Background checks may be requested by employers who are considering hiring you on a preliminary, preferred, or preferred + basis. Your account will be forwarded to the employer if Helper Ji does not suspend or terminate it as a result of the results of the check. Account suspensions and terminations may be the result of background checks.</p>
               <h3>Information that does not identify you personally</h3>
              <p>By this Privacy Policy, non-personal information is collected as well, such as cookie or web beacon data, or standard logs of website activity.

                <br /><br />If you choose not to allow Helper Ji to collect passive data about your activities on our site, you can disable cookies in your browser settings.
                
                All Non Personal Information we collect may be used and disclosed by our advertisers and other third parties.
                </p>

                <h3>Business transactions</h3>
                <p>Your Personal Information may be transferred when we merge, acquire another company, or sell all or part of our assets. Upon transfer, your Personal Information may be continued to be processed as described in this Policy by the acquirer or affiliate of the acquirer.</p>

                <h3>Sharing your personal information - how you can control it</h3>
                <p>
                  We offer our users the option of sharing optional Personal Information with us, such as pictures and job descriptions.

                  <br /><br />We can stop sending you marketing emails at any time by adjusting your email marketing settings. Other Helperji emails, such as educational ones, can also be opted out of in your account settings. 

                  <br /><br />By going to your account settings, you can choose to opt out of receiving certain types of emails. You can unsubscribe from our promotional emails by following the unsubscribe instructions in the email.

                  In Even though you indicated that you did not want email marketing, we may send you administrative emails regarding Helperji, such as notifications of changes to our Privacy Policy, notifications of new employers, job seekers, or Cleaning professionals in your area, responses to job postings from Jobseekers/Cleaning professionals or any other individual, and responses to job applications from Jobseekers/Cleaning professionals or any other individual, if we choose to do so.

                </p>

                <h3>Protecting your information is one of our top priorities</h3>
                <p>
                  Make sure your information is protected from unauthorized access by implementing industry-recognized security measures. As a safeguard, Helper Ji uses strong encryption technology to protect sensitive information.

                 <br /><br />Our systems are protected by firewalls, intrusion detection systems, and system monitoring to prevent unauthorized access. Helper Ji also protects your user account with a password so that only you can access your online account. By taking precautions, you can ensure the security of your password. The "My Profile & Settings" section of your account allows you to immediately change your password if it has been compromised. Your Helper Ji account should be logged out once you have finished using the site.

                </p>
                <h3>Other Websites Links</h3>
                <p>We hope our users will find other websites useful through links on the Helper Ji website. These websites are not under our control, and we are not responsible for the privacy practices employed by them. Helper Ji's Privacy Policy applies only to information collected by our website. It does not apply to information collected from third-party websites. Before using these other websites, our users need to familiarize themselves with their privacy policies.</p>
                <h3>Policy changes regarding privacy</h3>
                <p>Our Terms of Service or this policy may be changed by Helper Ji at any time. You will receive an email or prominent notice if we make any significant changes to how Personal Information is handled, so you can decide whether to continue using our services. </p>
                <p>Prior to the implementation of significant changes, you will be notified 30 days in advance. In the event of any non-material changes or clarifications to the policy, we will post them on our Site as soon as possible. Keep checking our website at <a href="https://helperji.in">https://helperji.in</a> for updates. As soon as such changes become effective, you are deemed to have accepted them by your use of the Site or Services.</p>
               


          </div>

      </div>
    </section>
        </Fragment>
    )
}

export default Privacy;
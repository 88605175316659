import React, { Fragment } from "react";
import Floatingcall from "../components/floatingbutton/Floatingcall";
import { Link } from "react-router-dom";

function Whyus(){

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

    return(
        <Fragment>
          
            <Floatingcall />
         
            <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Why Choose HelperJi ?</h2>
          <p>The reasons you should choose us...</p>
        </div>

        <div class="row gy-4">
              
              <div class="col-lg-6">
                <div class="content ps-0 ps-lg-5">
                  <h3>Our professional cleaning services ensure you are satisfied!</h3>
                  <ul>
                    <li>●	Our staff and services have been verified and trusted. </li>
                    <li>●	Protecting our customers' valuables and ensuring their safety.</li>
                    <li>●	A Team of Experts and Machines to Clean Your Home and office space.</li>
                  </ul>
                  <p>
                  The cleaning services provided by Helper Ji are professional and reliable. The deep cleaning services we offer include cleaning your residence, the kitchen, the bathroom, the floor, the sofa, the carpet, the lamps, the fixtures, the furniture, the lights, fans, the balcony, the walls, the windows, as well as the workplace.
                  </p>
                  <p>
                  Professional cleaners with the best equipment and chemicals, with extensive experience and trained staff. Every new employee undergoes a thorough background check and professional training.
                  </p>
                  <p>
                    We ensure that your life is as stress-free as possible. Our simple and easy-to-use platform makes it easy to connect with workers through HelperJi. Using our services will make your life much easier, more convenient, and more organized.
                  </p>
                  <div class="text-center"><Link to='/contact-us' className="booknow" onClick={scrollToTop}>Book Now</Link></div>
                </div>
              </div>
              <div class="col-lg-6">
                <img src="/assets/img/aboutus2.png" class="img-fluid rounded-4 mb-4" alt="" />
                
              </div>
            </div>
        </div>
    </section>

    <div class="bg-gray pt-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_one"><i class="bi bi-clipboard2-check-fill"></i></span>
            <h6>Trained & Verified Cleaners</h6>
            <p> We are one of the top cleaning service providers because of our passionate team of professionals. We hire only well-trained cleaners who have had their backgrounds checked before being hired to ensure the quality of our work.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_two"><i class="bi bi-award-fill"></i></span>
            <h6>Assurance of Quality</h6>
            <p>We will Make Your Office and Home sparkling with Our Professional Full Deep Cleaning Services. Our services are of the highest quality. As always, Helper Ji is committed to providing you with excellent service at reasonable prices.</p>
          </div>
        </div>
        
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_four"><i class="bi bi-shield-fill-check"></i></span>
            <h6>Safe & Affordable</h6>
            <p>Hire Helper Ji to clean your home and office to world-class standards and get 100% quality and professional attention. You can rely on us for the most affordable cleaning services, whether you need them for your home or commercial space. </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_five"><i class="bi bi-headset"></i></span>
            <h6>Hassle Free Booking</h6>
            <p>You can book your service now. Fill out the service booking form and select the date and time. Delivering services without hassles. No matter if you prefer to book online or by phone, you can book the service whenever is convenient for you.</p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_three"><i class="fa fa-hourglass-half"></i></span>
            <h6>24 x 7 Instant Support</h6>
            <p>Providing timely, detailed services, as promised, speaks volumes about our commitment to quality work. The ability to provide flexible and consistent customer service. Whenever our customers contact you, we will be there to answer their calls.</p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_six"><i class="bi bi-credit-card-fill"></i></span>
            <h6>100% Secure Online Payment</h6>
            <p>Online payments are 100% secure with Helper Ji. Paying for an appointment online is possible at the time you book it. A variety of payment gateways are available for online payments, including Visa and MasterCard.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
            
        </Fragment>
    )
}

export default Whyus;
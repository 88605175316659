import React, { Fragment } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


function Testinomials(){
    return(
    <Fragment>
      
      <section id="stats-counter" className="stats-counter">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Recent Works & Client's Testimonials</h2>
          <p>You can trust us for the following reasons. Don't take our word for it, Let's See what our clients are saying.</p>
        </div>
        <div className="row gy-4 align-items-center">

          <div className="col-lg-6">

        <Carousel autoPlay>
			<div>
				<img src="assets/img/washroom/1s.jpg" alt="washrroom" />
		
			</div>
			<div>
				<img src="assets/img/washroom/2s.jpg" alt="washroom"/>
				
			</div>
			<div>
				<img src="assets/img/washroom/3s.jpg" alt="washroom" />
				
			</div>
			<div>
				<img src="assets/img/washroom/4s.jpg" alt="washroom" />
				
			</div>
		</Carousel>
      </div>
   
       <div className="col-lg-6" style={{background:"var(--color-primary)", padding:"37px" , color:"#fff", borderRadius:"10px"}}>

        <Carousel showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={5000}>

          <div className="container">
          <img src="assets/img/testimonials/AmitParya.jpg" style={{width:"150px", borderRadius:"50%"}} alt="Amit Parya"/>
          <div className="myCarousel">
            <h3>Amit Parya</h3>
            <p>(Manager, Indian Bank)</p>
            <div className="stars">
                        <i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i>
                      </div>
            <p className="px-5 pt-2">
            We got easily the verification process done for the cleaning professional we hired for our home Delhi. 
                    The entire experience was quite seemless. The reports were sent to us post verification in a timely manner. 
                    I would be happy to recommend HelperJi.
            </p>
          </div>
        </div>

        <div className="container">
          <img src="assets/img/testimonials/Arunkhare.jpg" style={{width:"150px", borderRadius:"50%"}} alt="arun raj khare" />
          <div className="myCarousel">
            <h3>Arun Raj Khare</h3>
            <p>(Government Officer)</p>
            <div className="stars">
            <i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i>
                      </div>
            <p className="px-5 pt-2">
            The best thing about HelperJi is that very quick service with low fees as compare to others and a lot of helper options. 
                    we got very prompt response in terms of processing my request & getting the report.
                    I would definitely like to recommend the service to other.
            </p>
          </div>
        </div>

        <div className=" container">
          <img src="assets/img/testimonials/pradeep.jpg" style={{width:"150px", borderRadius:"50%"}} alt="Pradeep Yadav" />
          <div className="myCarousel">
            <h3>Pradeep Yadav</h3>
            <p>(Manager, Delhivery)</p>
            <div className="stars">
            <i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i><i className="bi bi-star-fill" style={{color:"#FEB300"}}></i>
                      </div>
            <p className="px-5 pt-2">
            HelperJi was very helpful in my search for a trustworthy cleaning service providers for our new home. 
                        we hired a cleaners from their platform & wanted a Background Check on her. 
                        the overall experience is very good, must recommend to others.
            </p>
          </div>
        </div>
        </Carousel>
          </div>

        </div>

      </div>
    </section>
        </Fragment>
    )
}

export default Testinomials;
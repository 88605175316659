import React, {Fragment} from "react";
import '../floatingbutton/floatingcall.css';


function Floatingcall(){
    return(
        <Fragment>
<div className="btn-wrap1"><a className="floating-btn1" href="tel:7800780095">< i className="bi bi-telephone-forward-fill"></i> 7800 7800 95</a>
  <div className="shadow1"></div>
</div>
        </Fragment>
    )
};

export default Floatingcall ;
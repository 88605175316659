import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Contactus from "../components/Contactus";
import Floatingcall from "../components/floatingbutton/Floatingcall";
import { Helmet } from "react-helmet";

function PrivateSecurityServices(){

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

    return(
        <Fragment>
          <Helmet>
            <title>Personal protection officer | Private security service</title>
            <meta name="tttle" content="Personal protection officer | Private security service" />
            <meta name="description" content="Enhance your safety with expert personal protection officers from our private security service. Trust in top-notch security for ultimate peace of mind." />
            <meta name="keywords" content="Event security services, Personal protection officer, Private security officer, Private security agents, Armed security services, Private Security Guard, Personal security guard, Private security personnel, Personal security officer, Private security service" />
          </Helmet>
            
            <Floatingcall />
         
            <section id="about" class="about">
            <div class="container" data-aos="fade-up">

            <div class="section-header">
            <h2>Private Security Services</h2>
            <p>Personal Security Officer Services at Helper Ji </p>
             </div>

           <div class="row gy-4">
           <div class="col-lg-6">
            <img src="/assets/img/security1.jpeg" class="img-fluid rounded-4 mb-4" alt="" />
            
            </div>
          
          <div class="col-lg-6">
            <div class="content ps-0 ps-lg-5">
              <h3 class="fst-italic">
              With Helper Ji's Personal Security Officer Services, we ensure your safety
              </h3>
              <p>
              In today's dynamic and often unpredictable world, ensuring personal safety has become a primary concern for individuals from all walks of life. <b> <a href="https://www.helperji.in/private-security-services">PSO Security Services</a> </b> offer a comprehensive solution to these concerns, providing dedicated professionals trained to safeguard clients against potential threats and risks. The security solutions Helper Ji provides are designed to meet each client's specific needs.
              </p>
              <p>We provide high-quality security solutions at an affordable price regardless of geographical location, owing to our extensive network of highly trained personal security guards effectively positioned nationwide. You can live life with confidence and security wherever you are with Helper Ji, as we provide comprehensive security and protection.</p>
              <p>Then why hesitate, take advantage of our <a href="https://www.helperji.in/">Helper Ji</a> <b><a href="https://www.helperji.in/private-security-services">personal security services</a></b>, and become completely tension-free. Now that we are here, there is no need to fear.</p>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section id="services" class="services sections-bg">
          <div class="container" data-aos="fade-up">
    
            <div class="row gy-4">
              
              <div class="col-lg-6">
                <div class="content ps-0 ps-lg-5">
                  <h2>
                  Why Choose Helperji's Private Security Services:
                  </h2>
                  <p>
                  When it comes to your security, choosing Helper Ji is a decision backed by several compelling reasons. As one of the top providers of armed security services, we provide a range of services. Our team is unmatched in skill and proficiency.
                  </p>
                  <p>We prioritize your needs and concerns above all else, ensuring that you receive personalized attention and solutions tailored to your specific requirements. Let Helper Ji take care of your security needs.</p>
                  <p>Each member of our private security guard team undergoes extensive security training and education to ensure your safety. Utilizing the most recent technology enhances the quality of our services. A cutting-edge surveillance system and communication tool keep you safe and secure. Finally, our dedication to client satisfaction is never-ending. </p>
                  
                  <div class="text-center"><Link to='/contact-us' className="booknow" onClick={scrollToTop}>Book Now</Link></div>
                </div>
              </div>
              <div class="col-lg-6">
                <img src="/assets/img/security2.jpg" class="img-fluid rounded-4 mb-4" alt="" />
                
              </div>
            </div>
    
          </div>
        </section>

       <Contactus />
        </Fragment>
    )
}

export default PrivateSecurityServices;
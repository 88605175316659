import React,{Fragment} from "react";
import Bookform from "../components/Bookform";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import Topbar from "../components/Topbar";


function Booknew(){
    return(
        <Fragment>
            <Topbar />
            <Menu />
            <Bookform />
            <Footer />
        </Fragment>
    )
}
export default Booknew;
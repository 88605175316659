import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Contactus from "../components/Contactus";
import Floatingcall from "../components/floatingbutton/Floatingcall";
import{Helmet} from "react-helmet";

function Helper(){

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

    return(
        <Fragment>
                <div >
                    <Helmet>
                        <title>Helper Registration | HelperJi</title>
                    </Helmet>
                </div>
            
            <Floatingcall />
            
            <section id="about" className="about">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>Home Cleaning Services</h2>
          <p> A professional Cleaning Service for your home</p>
        </div>

        <div className="row gy-4">
          <div className="col-lg-6">
            <img src="/assets/img/maidservice.jpg" className="img-fluid rounded-4 mb-4" alt="" />
            <div className="text-center"><Link to='/contact-us' className="booknow" onClick={scrollToTop}>Book Now</Link></div>
          </div>
          
          <div className="col-lg-6">
            <div className="content ps-0 ps-lg-5">
              <h3 className="fst-italic">
              You can hire Helper Ji to clean any part of your home for an affordable price. Our company also offers a variety of other services. Our<a href="https://www.helperji.in/">deep cleaning services in Noida</a> can restore your home, office, sofa, bathroom, etc. 
              </h3>
              <p>
              Keeping your home dust-free will make it look more beautiful and make an impression on visitors. To make your life as comfortable and happy as possible, we strive to provide you with everything you need. To make your everyday life easier, we organize every aspect of your home to make it easier for you to get things done.
              </p>
              <p>To perform our tasks effectively, we must ensure quality. Our cleaners use the latest equipment and techniques when cleaning your home assets. Our company is one of the most suitable choices for anyone looking for top-quality <a href="https://www.helperji.in/">cleaning services in Noida.</a></p>
            </div>
          </div>
        </div>

      </div>
    </section>

            <section id="services" className="services sections-bg">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="content ps-0 ps-lg-5">
                  <p>You can hire Helper Ji to clean any part of your home for an affordable price. Our company also offers a variety of other services. Our deep cleaning service can restore your home, office, sofa, car, bathroom, etc., to like-new condition. We are widely recognized by many satisfied customers as the industry's leading deep cleaning brand. Taking care of all cleaning chores for you is the goal of our cleaning services.</p>
                  <p>It is our team's professional training and years of experience that make us the best at house cleaning. We provide world-class <strong><a href="https://www.helperji.in/home-cleaning-services">home cleaning services</a></strong> as well as protection, so you can rest assured that your home and assets are safe. You can also keep your family healthy and sanitized by keeping your home in a calm and sanitized condition.</p>
                  <p>It is our goal to thoroughly clean your kitchen and bathroom every time we visit, so you can rest assured that we can do this for you. You will receive high-quality cleaning services from the cleaners of our company, as well as after-service support to ensure your satisfaction.</p>
                  <div className="text-center"><Link to='/contact-us' className="booknow" onClick={scrollToTop}>Book Now</Link></div>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="/assets/img/homeclean.jpg" className="img-fluid rounded-4 mb-4" alt="" />
                
              </div>
            </div>
    
          </div>
        </section>
        <section id="services" className="services sections-bg">
        <div className="container">
        <h3>
                  We provide the following services as part of our home cleaning service:
                  </h3>
                 
                  <b>●	Bedrooms</b>

                  <p>As the place where you spend most of your time, your bedroom comes as no surprise that it provides comfort and rest. Your bedroom should be cleaned more than just folding blankets and putting away clothes. It is very important to keep your bedroom clean from all sides to maintain a healthy and safe environment. With the help of house cleaning services from Helper Ji, you can keep your bedrooms clean efficiently and affordably.</p>



                 <b>●	Kitchen Cleaning</b>

                 <p>Each member of the household has a responsibility to clean the kitchen. To cater to this need, Helper Ji offers a separate service for kitchen cleaning. To prevent cockroaches and mice from breeding in your kitchen, you should remove cooking stains as soon as possible. Cleaning products used by our kitchen cleaning services will include eco-friendly cleaning solvents. A stove, a gas stove, an electric chimney, and a chimney can be found in cabinets, sinks, and countertops.</p>


                  <b>● Living Room</b>

                 <p>As the place where you host guests and conduct household activities, the living room is probably the most important part of any home. The living room should be thoroughly cleaned and deep cleaned frequently, even if it's necessary to do so often. Throughout the living room, picture frames will be thoroughly cleaned and dusted.</p>

                 <b>●	Bathroom Cleaning </b>

                  <p>The bathroom is a breeding ground for germs, which is why it must be kept clean and sanitized. The toilet, and the washbasin, usually accumulate dirt. Over time, bathroom flooring tends to become dirty. Keeping your bathrooms clean and tidy is one of our top priorities at Helper Ji. It is certainly beneficial to maintain good health while having a professional cleaning company like Helper Ji on your side.
                  </p>
                  <p>
                  Among the areas included in our service are picture frames, ceiling fans, beds, tables, sofas, chairs, dusting and detailing of walls, fans, lights, doors, switchboards, shelves, AC & appliance exteriors, as well as Windows and glass. We use a commercial vacuum cleaner to clean your rugs, cushions, carpets, mats, chairs & blinds, and clean most windows (both sides), tables, doors, mirrors, and shower cabinets. Cleaning, disinfecting, and scrubbing the floors of the kitchen, dining room, hall, bathroom, and balcony. We can always take care of your quick-dry cleaning whenever you need it. It would be appreciated if you could have us take care of all the cleaning for you with our <strong><a href="https://www.helperji.in/home-cleaning-services">Home cleaning services in Noida</a></strong>. You will receive assistance from our team in the shortest amount of time possible.

                   </p>
        </div>
        </section>
            

            <Contactus />

        </Fragment>
    )
}

export default Helper;

import React, { Fragment } from "react";
import { Link } from "react-router-dom";
 
function Homeservices(){

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
    return(
        <Fragment>
            <section id="services" class="services sections-bg">

            <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Our Cleaning Services</h2>
              <p>In addition to offering timely <strong><a href="https://www.helperji.in/">Deep Cleaning Services in Noida</a></strong>, quality work, and competitive prices, we have always been committed to providing the highest customer service. We keep in touch with our customers by providing quality, performance, services, and assistance. As well as offering our customers the best value, we also care about their satisfaction. </p>
            </div>
            <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-3 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img src="assets/img/icons/commercialcleaning100.png" alt="nanny" className="service-img" />
                  </div>
                  <h3>Commercial Cleaning</h3>
                  <p>We provide world-class commercial cleaning services as well as protection, so you can rest assured that your home and assets are safe.</p>
                    <Link to='/book-now' className="readmore stretched-link" onClick={scrollToTop}>Book now <i className="bi bi-arrow-right"></i></Link>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img src="assets/img/icons/housekeeping-manpower-services.svg" alt="Chef" className="service-img" style={{width: '120px'}} />
                  </div>
                  <h3>Housekeeping Manpower</h3>
                  <p>Contact us today for professional housekeeping manpower services. Book now at affordable rates with the best service quality.</p>
                    <Link to='/book-now' className="readmore stretched-link" onClick={scrollToTop}>Book now <i className="bi bi-arrow-right"></i></Link>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="assets/img/icons/security-guard-icon.svg" alt="nanny" className="service-img" />
                  </div>
                  <h3>Security Guard</h3>
                  <p>Helper Ji takes pleasure in offering the best security guard services available in Noida, making sure your property is safe and secure.</p>
                    <Link to='/book-now' className="readmore stretched-link" onClick={scrollToTop}>Book now <i className="bi bi-arrow-right"></i></Link>
                </div>
              </div>

              
              <div className="col-lg-3 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="assets/img/icons/private-security-service.svg" alt="sanitization services" className="service-img" />
                  </div>
                  <h3>Personal Security</h3>
                  <p>
                    With Helper Ji's Personal Security Officer Services, we ensure your safety, you
                    have come to the right place.</p>
                    <Link to='/book-now' className="readmore stretched-link" onClick={scrollToTop}>Book now <i className="bi bi-arrow-right"></i></Link>
                </div>
              </div>

            </div>
            </div>
            </section>

        </Fragment>
    )
}
export default Homeservices;
import React, { Fragment } from "react";

function Topbar(){
    return(
        <Fragment>
            <section id="topbar" className="topbar d-flex align-items-center">
    <div className="container d-flex justify-content-center justify-content-md-between">
      <div className="contact-info d-flex align-items-center">
        <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:contact@helperji.in">contact@helperji.in</a></i>
        <i className="bi bi-phone d-flex align-items-center ms-4"><span>7800 7800 95</span></i>
      </div>
      <div className="social-links d-none d-md-flex align-items-center">
        <a href="https://twitter.com/helperji_" className="twitter" target="_blank" rel="noopener noreferrer"><i className="bi bi-twitter"></i></a>
        <a href="https://www.facebook.com/helperjiofficial" className="facebook" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a>
        <a href="https://www.instagram.com/helperji_official/" className="instagram" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>
        <a href="https://in.pinterest.com/helperjiofficial/" className="linkedin" target="_blank" rel="noopener noreferrer"><i className="bi bi-pinterest"></i></a>
      </div>
    </div>
  </section>
        </Fragment>
    )
}

export default Topbar;
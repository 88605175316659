import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Bloghome(){

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
    return(
        <Fragment>
         <section id="recent-posts" className="recent-posts sections-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>Our Latest Blogs</h2>
          <p></p>
        </div>

        <div className="row gy-4">

          <div className="col-xl-4 col-md-6">
            <article>

              <div className="post-img">
                <img src="assets/img/blog/cleaningservice3.png" alt="" className="img-fluid" />
              </div>

              <p className="post-category">Cleaning Services</p>

              <h3 className="title">
              <Link to="blog/high-quality-cleaning-services-in-noida" onClick={scrollToTop}>Using High-Quality Cleaning Services can benefit your Health in several ways</Link>
              </h3>

              <div className="d-flex align-items-center">
                <img src="assets/img/blog/writer-male.png" alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author">Avinash Kumar</p>
                  <p className="post-date">
                    <time datetime="2023-11-01">Nov 1, 2023</time>
                  </p>
                </div>
              </div>

            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>

              <div className="post-img">
                <img src="assets/img/blog/homecleaning.jpg" alt="" className="img-fluid" />
              </div>

              <p className="post-category">Home Cleaning</p>

              <h3 className="title">
              <Link to='blog/professional-cleaning-services-in-noida' onClick={scrollToTop}>Make Sure Your Home Is Sparkling with Professional Cleaning Services</Link>
              </h3>

              <div className="d-flex align-items-center">
                <img src="assets/img/blog/writer-female.png" alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author">Kirti Singh</p>
                  <p className="post-date">
                    <time datetime="2023-12-06">Dec 6, 2023</time>
                  </p>
                </div>
              </div>

            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>

              <div className="post-img">
                <img src="assets/img/blog/officeCleaning.jpg" alt="" className="img-fluid" />
              </div>

              <p className="post-category">Office Cleaning</p>

              <h3 className="title">
              <Link to="blog/commercial-cleaning-services-in-noida" onClick={scrollToTop}>Choosing the right Commercial Cleaning Company for your Office is important</Link>
              </h3>

              <div className="d-flex align-items-center">
                <img src="assets/img/blog/writer-male.png" alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author">Ankit Singh</p>
                  <p className="post-date">
                    <time datetime="2024-01-10">Jan 10, 2024</time>
                  </p>
                </div>
              </div>

            </article>
          </div>

        </div>

      </div>
    </section>
        </Fragment>
    )
}

export default Bloghome;